import React from 'react';
import { Carousel } from 'react-bootstrap';
import { range } from 'lodash';

const CompetitionSidebar = () => {
  return (
    <Carousel controls={false}>
      {range(5, 6).map((i) => {
        return (
          <Carousel.Item key={i}>
            <img
              className="d-block w-100"
              src={process.env.PUBLIC_URL + "/assets/images/slider/SliderLeft" + i + ".png"}
              alt=""
            />
          </Carousel.Item>
        )
      })}
    </Carousel>
  )
}

export default CompetitionSidebar;