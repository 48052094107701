import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Button, Table, Modal, FormControl, InputGroup, Tooltip, Overlay, Form } from 'react-bootstrap';
import { ArrowReturnLeft, Trash, Eraser } from 'react-bootstrap-icons';
import { useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWorkshops, fetchGroups } from '../redux/actions/productActions';
import { updateCart, onlineCheckout, verifyCoupon, clearCoupon } from '../redux/actions/orderActions';
import { Controller, useForm } from "react-hook-form";
import Stepper from 'bs-stepper';
import DatePicker from 'react-datepicker';
import PhoneInput from 'react-phone-input-2';
import BootstrapTable from 'react-bootstrap-table-next';
import { BsPencil, BsTrash } from 'react-icons/bs'
import { filter, find, findIndex, intersectionWith, isEmpty, isEqual, pullAt, range, reject } from 'lodash';
import dayjs from 'dayjs';
import dayjsRecur from 'dayjs-recur'
import PaymentForm from '../components/PaymentForm';
import Ipay88Form from '../components/Ipay88Form';
import CompetitionSidebar from '../components/CompetitionSidebar';
import { getCompetition, getCompetitionVariants, getTicketPrice, getGroupsByVariants, getGroupsByAge, getGroupsByGender, getGroupsByCategory, getAgeGrpDesc } from '../lib/product';
import config from '../config.json';
import countries from '../data/countries.json';

dayjs.extend(dayjsRecur)

const Competitions = () => {
  const dispatch = useDispatch();
  const tooltipRef = useRef(null);
  const workshops = useSelector(state => state.productData.workshops);
  const groups = useSelector(state => state.productData.groups);
  const orders = useSelector(state => state.orderData);
  const [stepper, setStepper] = useState();
  const [daysAhead, setDaysAhead] = useState(0);
  const [selectedTickets, setSelectedTickets] = useState({});
  const [registeredParticipants, setRegisteredParticipants] = useState([]);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showParticipantModal, setShowParticipantModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedParticipant, setSelectedParticipant] = useState({});
  const [participantMobile, setParticipantMobile] = useState({});
  const [participantAgeError, setParticipantAgeError] = useState('');
  const [participantGenderError, setParticipantGenderError] = useState('');
  const [participantCategoryError, setParticipantCategoryError] = useState('');
  const [emergencyPhone, setEmergencyPhone] = useState({});
  const [competition, setCompetition] = useState([]);
  const [competitionVariants, setCompetitionVariants] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [customer, setCustomer] = useState({});
  const [cartCustomer, setCartCustomer] = useState({});
  const [couponCode, setCouponCode] = useState('');
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [couponError, setCouponError] = useState('');
  const [visitDate, setVisitDate] = useState(null);

  let ticketCount = 0;
  let subTotal = 0;
  let ticketPrices = [];
  let normalPrices = [];

  const { payment } = orders;

  const { eventid } = useParams();
  const { pathname } = useLocation();
  const path = pathname.split('/')[1];
  const urlParams = new URLSearchParams(window.location.search);
  const orderId = urlParams.get('order_id');

  const actionButton = (cell, row, rowIndex, formatExtraData) => {
    return (
      <Fragment>
        <span className="table-button" onClick={() => handleSelectedAction("edit", row)}><BsPencil /></span>
        <span className="table-button" onClick={() => handleSelectedAction("delete", row)}><BsTrash /></span>
      </Fragment>
    )
  };

  const participantsTableColumns = [{
    dataField: 'id',
    text: 'Id',
    hidden: true
  }, {
    dataField: 'sku',
    text: 'Sku',
    hidden: true
  }, {
    dataField: 'type',
    text: 'Type',
    sort: true
  }, {
    dataField: 'name',
    text: 'Name',
    sort: true,
    style: () => {
      return { wordWrap: "break-word" };
    }
  }, {
    dataField: 'email',
    text: 'Email',
    style: () => {
      return { wordWrap: "break-word" };
    }
  }, {
    dataField: 'dob',
    text: 'Birth Date'
  }, {
    dataField: 'mobile',
    text: 'Phone No',
    style: () => {
      return { wordWrap: "break-word" };
    }
  }, {
    dataField: 'country_code',
    text: 'Country Code',
    hidden: true
  }, {
    dataField: 'number_only',
    text: 'Number Only',
    hidden: true
  }, {
    dataField: 'gender',
    text: 'Gender',
    sort: true
  }, {
    dataField: 'action',
    text: '',
    formatter: actionButton,
    headerStyle: () => {
      return { width: "10%" };
    },
    style: () => {
      return { padding: "12px 0px" };
    }
  }];

  const {
    handleSubmit: handleSubmitTickets,
    getValues,
    setValue: setTicketsValue,
    clearErrors,
    errors: errorsTickets,
    control: controlTickets
  } = useForm({
    mode: 'onChange'
  });

  const {
    handleSubmit: handleSubmitParticipants,
    watch: watchParticipants,
    errors: errorsParticipants,
    control: controlParticipants
  } = useForm({
    mode: 'onChange'
  });

  const participantDob = watchParticipants('participantsdob');
  const participantGender = watchParticipants('participantsgender');
  const participantCat = watchParticipants('category');

  const handleDeleteParticipant = (confirm) => {
    if (confirm) {
      setRegisteredParticipants(reject(registeredParticipants, ['id', selectedParticipant.delete.id]));

      const count = getValues(selectedParticipant.delete.sku);
      setTicketsValue(selectedParticipant.delete.sku, +count - 1, { shouldValidate: true });

      setSelectedTickets({
        ...selectedTickets,
        [selectedParticipant.delete.sku]: +count - 1
      });
    }

    setSelectedParticipant({});
    setShowDeleteModal(false);
  };

  const handleSelectedAction = (action, selectedRow) => {
    let participantObject = {};
    participantObject[action] = find(registeredParticipants, ['id', selectedRow.id]);
    setSelectedParticipant(participantObject);
  };

  const validateTicketCount = () => {
    const data = getValues();
    ticketCount = 0;
    if (data) {
      for (let key in data) {
        if (key !== 'visitdate') {
          clearErrors([key]);
          if (data[key]) {
            ticketCount += parseInt(data[key]);
          }
        }
      }
    }
    if (ticketCount) return true;
    else return false;
  };

  const onSubmitTickets = (data) => {
    if (data) {
      data.visitdate = visitDate ? visitDate : dayjs(competition[0].event[0].start).startOf('day').toDate();

      const retryPath = {
        link: "competition",
        eventid: eventid
      }

      let participantData = [];

      if (registeredParticipants && registeredParticipants.length > 0) {

        registeredParticipants.forEach((registeredParticipant) => {
          let participant = {
            type: registeredParticipant.type,
            sku: registeredParticipant.sku,
            name: registeredParticipant.name,
            email: registeredParticipant.email,
            phone: {
              country_code: registeredParticipant.country_code,
              number: registeredParticipant.number_only
            },
            emergency_contact: registeredParticipant.emergencycontact,
            emergency_phone: {
              country_code: registeredParticipant.emergency_country_code,
              number: registeredParticipant.emergency_number_only
            },
            dob: dayjs(registeredParticipant.dob).startOf('day').toDate(),
            gender: registeredParticipant.gender,
            nationality: registeredParticipant.nationality,
            category: registeredParticipant.category,
            tshirt_size: registeredParticipant.tshirt_size,
            waiver: registeredParticipant.waiver
          };

          participantData.push(participant);
        });
      }

      let items = [];
      for (let key in data) {
        if (parseInt(data[key])) {
          let variant = find(competitionVariants, ['sku', key]);
          let ticketPrice = find(ticketPrices, (o) => { return o[key] });
          let normalPrice = find(normalPrices, (o) => { return o[key] });

          range(parseInt(data[key])).forEach(() => {
            let participantIndex = findIndex(participantData, ['sku', variant.sku]);

            if (variant) {
              const parentWorkshop = find(workshops, (ws) => {
                return ws.variants.includes(variant)
              });

              items.push({
                ...variant,
                quantity: 1,
                visit_date: dayjs(data.visitdate).startOf('day').toDate(),
                type: "COMPETITION",
                name: parentWorkshop?._id + " " + variant.group,
                price: isEmpty(ticketPrice) ? null : ticketPrice[key],
                normalPrice: isEmpty(normalPrice) ? null : normalPrice[key],
                participant: pullAt(participantData, [participantIndex])?.[0]
              });
            }
          });
        }
      }

      dispatch(updateCart(items, ticketCount, subTotal, retryPath));
      stepper.next();
    }
  };

  const onSubmitParticipants = (data) => {
    if (data) {
      const competitionGroups = getGroupsByVariants(competitionVariants, groups);
      const age = dayjs().startOf('day').diff(dayjs(data.participantsdob).startOf('day'), 'year');

      let competitionType = "";
      let competitionDesc = "";
      let competitionSku = "";

      const filteredAge = getGroupsByAge(competitionGroups, age);
      if (!(filteredAge && filteredAge.length > 0)) {
        setParticipantAgeError("Do not have competition that match the age.");
      }

      const filteredGender = getGroupsByGender(competitionGroups, data.participantsgender);
      if (!(filteredGender && filteredGender.length > 0)) {
        setParticipantGenderError("Do not have competition that match the gender.");
      }

      const filteredCat = getGroupsByCategory(competitionGroups, data.category);
      if (!(filteredCat && filteredCat.length > 0)) {
        setParticipantCategoryError("Do not have competition that match the category.");
      }


      if (!(filteredAge && filteredAge.length > 0) || !(filteredGender && filteredGender.length > 0) || !(filteredCat && filteredCat.length > 0)) return;

      const filteredGroup = intersectionWith(filteredAge, filteredGender, filteredCat, isEqual);

      // Assume one competition variant per age group per gender
      if (filteredGroup && filteredGroup.length > 0) {
        competitionType = filteredGroup[0].group;
        competitionDesc = getAgeGrpDesc(filteredGroup[0]);
        competitionSku = filteredGroup[0].sku;
      } else {
        setParticipantAgeError("Do not have competition that match both age and gender.");
        setParticipantGenderError("Do not have competition that match both age and gender.");
        setParticipantCategoryError("Do not have competition that match the category.");
        return;
      }

      if (competitionType) {
        if (!isEmpty(selectedParticipant)) {
          const index = findIndex(registeredParticipants, ['id', selectedParticipant.edit.id]);

          registeredParticipants[index].type = competitionDesc;
          registeredParticipants[index].sku = competitionSku;
          registeredParticipants[index].name = data.participantsname;
          registeredParticipants[index].email = data.participantsemail;
          registeredParticipants[index].mobile = data.participantsmobile;
          registeredParticipants[index].country_code = participantMobile.country_code;
          registeredParticipants[index].number_only = participantMobile.number;
          registeredParticipants[index].dob = dayjs(data.participantsdob).startOf('day').format('DD MMMM YYYY');
          registeredParticipants[index].gender = data.participantsgender;
          registeredParticipants[index].nationality = data.nationality;
          registeredParticipants[index].emergencycontact = data.emergencycontact;
          registeredParticipants[index].emergencyphone = data.emergencyphone;
          registeredParticipants[index].emergency_country_code = emergencyPhone.country_code;
          registeredParticipants[index].emergency_number_only = emergencyPhone.number;
          registeredParticipants[index].category = data.category;
          registeredParticipants[index].tshirt_size = data.tshirt_size;
          registeredParticipants[index].waiver = true;
          setRegisteredParticipants([...registeredParticipants])
        } else {
          setRegisteredParticipants([
            ...registeredParticipants,
            {
              id: Date.now(),
              type: competitionDesc,
              sku: competitionSku,
              name: data.participantsname,
              email: data.participantsemail,
              mobile: data.participantsmobile,
              country_code: participantMobile.country_code,
              number_only: participantMobile.number,
              dob: dayjs(data.participantsdob).startOf('day').format('DD MMMM YYYY'),
              gender: data.participantsgender,
              nationality: data.nationality,
              emergencycontact: data.emergencycontact,
              emergencyphone: data.emergencyphone,
              emergency_country_code: emergencyPhone.country_code,
              emergency_number_only: emergencyPhone.number,
              category: data.category,
              tshirt_size: data.tshirt_size,
              waiver: true
            }
          ]);
        }
      }

      setSelectedParticipant({});
      setShowParticipantModal(false);
    }
  };

  const getCustomer = (customer) => {
    setCustomer(customer)
  };

  const handleCancelUpdate = () => {
    setSelectedParticipant({});
    setShowParticipantModal(false);
  }

  const handleChangeTickets = (e) => {
    setSelectedTickets({
      ...selectedTickets,
      [e.target.name]: e.target.value
    });
  };

  const handleCouponCode = () => {
    if (couponCode.length === 0) return;

    if (couponError.length > 0) {
      setCouponCode('');
      return;
    }

    if (couponDiscount > 0) {
      dispatch(clearCoupon());
    } else
      dispatch(verifyCoupon({ code: couponCode, cart: orders.cart }));
  };

  const handleBack = () => {
    dispatch(clearCoupon());
    stepper.previous();
  };

  const handleChangeParticipantMobile = (value, data) => {
    setParticipantMobile({
      country_code: data.dialCode,
      number: value.toString().replace(data.dialCode, '')
    })
  };

  const handleChangeEmergencyPhone = (value, data) => {
    setEmergencyPhone({
      country_code: data.dialCode,
      number: value.toString().replace(data.dialCode, '')
    })
  };

  useEffect(() => {
    dispatch(fetchWorkshops());
    dispatch(fetchGroups());
  }, [dispatch]);

  useEffect(() => {
    setCompetition(
      getCompetition(
        workshops,
        path,
        eventid
      )
    );
  }, [workshops, path, eventid]);

  useEffect(() => {
    setCompetitionVariants(
      getCompetitionVariants(competition)
    );

    if (competition && competition.length) {
      setVisitDate(dayjs(competition[0].event[0].start).startOf('day').toDate());
    }
  }, [competition]);

  useEffect(() => {
    setStepper(new Stepper(document.querySelector('.bs-stepper'), { animation: true }));
  }, []);

  useEffect(() => {
    if (visitDate) {
      setDaysAhead(dayjs(visitDate).startOf('day').diff(dayjs().startOf('day'), 'day'))
    }
  }, [visitDate]);

  useEffect(() => {
    if (!isEmpty(selectedParticipant)) {
      if (Object.keys(selectedParticipant).includes("edit")) {
        setShowParticipantModal(true);
      } else if (Object.keys(selectedParticipant).includes("delete")) {
        setShowDeleteModal(true);
      }
    }
  }, [selectedParticipant]);

  useEffect(() => {
    if (registeredParticipants.length > 0) {
      let tickets = {};
      const competitionGroups = getGroupsByVariants(competitionVariants, groups);

      competitionGroups &&
        competitionGroups.forEach((competitionGroup) => {
          let count = 0;
          count = filter(registeredParticipants, (participant) => {
            return participant.sku === competitionGroup.sku;
          }).length;

          tickets[competitionGroup.sku] = count;
          setTicketsValue(competitionGroup.sku, count.toString(), { shouldValidate: !isEmpty(errorsTickets) });
        });

      setSelectedTickets(tickets);
    }
  }, [registeredParticipants, errorsTickets, competitionVariants, setTicketsValue]);

  useEffect(() => {
    if (!isEmpty(customer))
      dispatch(onlineCheckout(customer));
  }, [customer, dispatch]);

  useEffect(() => {
    if (orderId) {
      if (orders.cart.items && orders.cart.items.length > 0) {

        setVisitDate(dayjs(orders.cart.items[0].visit_date).startOf('day').toDate());

        let selectTickets = {};
        let restoreParticipants = [];
        orders.cart.items.forEach((item) => {
          selectTickets[item.sku] = selectTickets[item.sku] ?
            (parseInt(selectTickets[item.sku]) + item.quantity).toString() :
            item.quantity.toString();

          if (item.participant) {
            restoreParticipants.push({
              id: Date.now(),
              type: item.participant.type,
              sku: item.participant.sku,
              name: item.participant.name,
              email: item.participant.email,
              dob: dayjs(item.participant.dob).startOf('day').format('DD MMMM YYYY'),
              gender: item.participant.gender,
              mobile: item.participant.phone.country_code + item.participant.phone.number,
              country_code: item.participant.phone.country_code,
              number_only: item.participant.phone.number
            });
          }
        });
        setSelectedTickets(selectTickets);

        if (restoreParticipants.length > 0)
          setRegisteredParticipants(restoreParticipants);
      }

      setCartCustomer(orders.cart.customer);
    }
  }, [orderId]);

  useEffect(() => {
    if (!couponCode || couponCode.length === 0) {
      dispatch(clearCoupon());
    }
  }, [couponCode]);

  useEffect(() => {
    const { coupon } = orders.cart;

    if (coupon) {
      let dataType = typeof coupon;
      if (dataType === 'object') {
        if (coupon.total_discount > 0) {
          setCouponError('');
          setCouponDiscount(orders.cart.coupon.total_discount);
        } else {
          setCouponError('The code is not applicable to this order');
          setCouponDiscount(0);
        }
      } else if (dataType === 'string') {
        setCouponError(orders.cart.coupon);
        setCouponDiscount(0);
      }
    } else {
      setCouponCode('');
      setCouponError('');
      setCouponDiscount(0);
    }
  }, [orders.cart.coupon]);

  useEffect(() => {
    setParticipantAgeError('');
  }, [participantDob]);

  useEffect(() => {
    setParticipantGenderError('');
  }, [participantGender]);

  useEffect(() => {
    setParticipantCategoryError('');
  }, [participantCat]);

  return (
    <Container fluid className="layout">
      <Row className="space-ptb-10">
        <Col lg={3} className="d-none d-lg-block">
          <CompetitionSidebar />
        </Col>
        <Col lg={9} md={12}>
          <div id="stepperForm" className="bs-stepper" style={showLoading ? { display: "none" } : {}}>
            <div className="bs-stepper-header" role="tablist">
              <div className="step" data-target="#competitions-form-1">
                <Button className="step-trigger" role="tab" id="stepperFormTrigger1" aria-controls="competitions-form-1" disabled>
                  <span className="bs-stepper-circle">1</span>
                  <span className="bs-stepper-label">Buy Ticket(s)</span>
                </Button>
              </div>
              <div className="bs-stepper-line"></div>
              <div className="step" data-target="#competitions-form-2">
                <Button className="step-trigger" role="tab" id="stepperFormTrigger2" aria-controls="competitions-form-2" disabled>
                  <span className="bs-stepper-circle">2</span>
                  <span className="bs-stepper-label">Order Summary</span>
                </Button>
              </div>
              <div className="bs-stepper-line"></div>
              <div className="step" data-target="#competitions-form-3">
                <Button className="step-trigger" role="tab" id="stepperFormTrigger3" aria-controls="competitions-form-3" disabled>
                  <span className="bs-stepper-circle">3</span>
                  <span className="bs-stepper-label">Payment</span>
                </Button>
              </div>
            </div>
            <div className="bs-stepper-content">
              <div id="competitions-form-1" role="tabpanel" className="bs-stepper-pane fade" aria-labelledby="stepperFormTrigger1">
                {competition && competition.length > 0 ? (
                  <form onSubmit={handleSubmitTickets(onSubmitTickets)}>
                    <Row className="form-body">
                      <BootstrapTable
                        className="participant-table"
                        keyField="id"
                        bootstrap4={true}
                        striped={true}
                        caption={`Register for ${competition[0]._id} [${dayjs(competition[0].event[0].start).startOf('day').format('DD/MM/YYYY')}]`}
                        data={registeredParticipants}
                        columns={participantsTableColumns}
                        selectRow={{
                          mode: 'checkbox',
                          clickToSelect: true,
                          hideSelectColumn: true,
                        }}
                        noDataIndication={"No registration. Minimum 1 registration."}
                      />
                    </Row>
                    <Row className="center-button space-mb-30">
                      <Button className="btn btn-primary mobile-size" onClick={() => setShowParticipantModal(true)}>Add</Button>
                    </Row>
                    <div className="d-flex justify-content-center grey-panel">
                      <Row className="d-block grey-panel-row">
                        <span className="row-space-2">Competition Categories</span>
                      </Row>
                    </div>
                    <Row className="form-body">
                      <Table>
                        <thead>
                          <tr>
                            <th className="mobile-hide"></th>
                            <th className="align-middle">Types of Categories</th>
                            <th className="align-middle">Online Price (RM)</th>
                            <th className="align-middle">Quantity</th>
                            <th className="align-middle">Total (RM)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {competitionVariants.map((variant, i) => {
                            const oriPrice = getTicketPrice(variant.price, 0);
                            const ticketPrice = getTicketPrice(variant.price, daysAhead);
                            const totalPrice = selectedTickets[variant.sku] ? selectedTickets[variant.sku] * ticketPrice.value : 0;

                            let opObj = {};
                            opObj[variant.sku] = oriPrice;
                            normalPrices.push(opObj);

                            let tpObj = {};
                            tpObj[variant.sku] = ticketPrice;
                            ticketPrices.push(tpObj);

                            subTotal += totalPrice;
                            return (
                              <tr key={i}>
                                <td className="mobile-hide align-middle">
                                  <img
                                    src={
                                      variant.photos && variant.photos.length > 0 ? (
                                        config.image.imageServerURL + variant.photos[0] + config.image.imageResizeTag + config.image.imageResizeProduct
                                      ) : ""
                                    }
                                    alt={variant.group}
                                  />
                                </td>
                                <td className="align-middle">
                                  {variant.desc}
                                </td>
                                <td className="align-middle">
                                  {oriPrice.value > ticketPrice.value &&
                                    <Fragment>
                                      <span className="price-discount">{oriPrice.value.toFixed(2)}</span>{' '}
                                      <br className="mobile-only"></br>
                                    </Fragment>
                                  }
                                  {ticketPrice.value.toFixed(2)}
                                </td>
                                <td className="align-middle">
                                  <Controller
                                    name={variant.sku}
                                    control={controlTickets}
                                    defaultValue={selectedTickets[variant.sku] ? selectedTickets[variant.sku].toString() : ""}
                                    render={({ name, onChange, value }) => (
                                      <FormControl
                                        className={"quantity"}
                                        name={name}
                                        as="select"
                                        value={value}
                                        disabled
                                        onChange={(e) => {
                                          onChange(e.target.value);
                                          handleChangeTickets(e);
                                        }}
                                      >
                                        <option value='' key=''></option>
                                        {range(variant.min_qty, variant.min_qty * 20).map((i) => {
                                          return (
                                            <option value={i.toString()} key={i}>{i}</option>
                                          )
                                        })}
                                      </FormControl>
                                    )}
                                    rules={{ validate: validateTicketCount }}
                                  />
                                </td>
                                <td className="align-middle">
                                  {totalPrice.toFixed(2)}
                                </td>
                              </tr>
                            )
                          })}
                          <tr className="grey-panel">
                            <td className="mobile-hide"></td>
                            <td colSpan='3' className="text-right">Subtotal (RM)</td>
                            <td>{subTotal.toFixed(2)}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Row>
                    <Row className="row-space-20">
                      <Col className="space-pd-0 text-left">
                        <Button className="btn btn-danger mobile-size" onClick={() => setShowCancelModal(true)}>Cancel</Button>
                      </Col>
                      <Col className="space-pd-0 text-right">
                        <Button className="btn btn-primary mobile-size" type="submit">Next</Button>
                      </Col>
                    </Row>
                  </form>
                ) : (
                  <h3>No Competition Found</h3>
                )}
              </div>
              <div id="competitions-form-2" role="tabpanel" className="bs-stepper-pane fade" aria-labelledby="stepperFormTrigger2">
                {orders &&
                  orders.cart &&
                  orders.cart.items &&
                  orders.cart.items.length > 0 ? (
                  <Fragment>
                    <h4 className="row-space-20">Summary of your order items</h4>
                    <p className="mobile-only">
                      Visit Date: {dayjs(orders.cart.items[0].visit_date).startOf('day').format('DD MMMM YYYY')}
                    </p>
                    <Row>
                      <Table>
                        <thead>
                          <tr>
                            <th>Item(s)</th>
                            <th className="mobile-hide">Visit Date</th>
                            <th>Price (RM)</th>
                            <th>Quantity</th>
                            <th>Subtotal (RM)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {orders.cart.items.map((item, i) => {
                            const oriPrice = getTicketPrice([item.normalPrice], 0);
                            const ticketPrice = getTicketPrice([item.price], daysAhead);
                            const subTotalPrice = item.quantity * ticketPrice.value;
                            return (
                              <tr key={i}>
                                <td>{item.desc}</td>
                                <td className="mobile-hide">{dayjs(item.visit_date).startOf('day').format('DD MMMM YYYY')}</td>
                                <td>
                                  {oriPrice.value > ticketPrice.value &&
                                    <Fragment>
                                      <span className="price-discount">{oriPrice.value.toFixed(2)}</span>{' '}
                                    </Fragment>
                                  }
                                  {ticketPrice.value.toFixed(2)}
                                </td>
                                <td>{item.quantity}</td>
                                <td>{subTotalPrice.toFixed(2)}</td>
                              </tr>
                            )
                          })}
                          <tr className="text-bold">
                            <td className="mobile-hide"></td>
                            <td colSpan='2' className="text-right">Ticket Amount</td>
                            <td></td>
                            <td>{orders.cart.subtotal?.toFixed(2)}</td>
                          </tr>
                          <tr className="text-bold">
                            <td className="mobile-hide"></td>
                            <td colSpan='2' className="text-right" style={{ verticalAlign: 'middle' }}>
                              {(orders.cart.coupon && couponDiscount > 0)
                                ? 'Discount (' + orders.cart.coupon.code + ')'
                                : 'Discount'
                              }
                            </td>
                            <td colSpan='2'>
                              {(orders.cart.coupon && couponDiscount > 0)
                                ? <div style={{ verticalAlign: 'middle' }}>- {couponDiscount.toFixed(2)} <Trash size={18} style={{ float: 'right', cursor: 'pointer' }} onClick={handleCouponCode} /></div>
                                : <InputGroup size="sm">
                                  <FormControl
                                    ref={tooltipRef}
                                    placeholder="Coupon"
                                    aria-label="Coupon"
                                    aria-describedby="coupon-code"
                                    className="mobile-size"
                                    htmlSize="10"
                                    onChange={(e) => setCouponCode(e.target.value)}
                                    value={couponCode}
                                    onKeyPress={(key) => {
                                      if (key.code === 'Enter' || key.code === 'NumpadEnter') handleCouponCode();
                                    }}
                                    disabled={couponDiscount > 0}
                                  />
                                  <Overlay target={tooltipRef} show={couponError.length > 0} placement='top'>
                                    {(props) => (
                                      <Tooltip id="mesg-tooltip" {...props}>
                                        {couponError}
                                      </Tooltip>
                                    )}
                                  </Overlay>
                                  <InputGroup.Append>
                                    <Button variant="outline-secondary" className="mobile-size" onClick={handleCouponCode}>{(couponError.length > 0) ? <Eraser /> : <ArrowReturnLeft />}</Button>
                                  </InputGroup.Append>
                                </InputGroup>
                              }
                            </td>
                          </tr>
                          <tr className="text-bold">
                            <td className="mobile-hide"></td>
                            <td colSpan='2' className="text-right">TOTAL (RM)</td>
                            <td></td>
                            <td>{(orders.cart.subtotal - couponDiscount)?.toFixed(2)}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Row>
                    <Row className="row-space-20">
                      <Col className="space-pd-0 text-left">
                        <Button className="btn btn-danger mobile-size" onClick={() => setShowCancelModal(true)}>Cancel</Button>
                      </Col>
                      <Col className="space-pd-0 text-right">
                        <Button className="btn btn-primary mobile-size" onClick={handleBack}>Back</Button>
                        <span>{' '}</span>
                        <Button className="btn btn-primary mobile-size" onClick={() => stepper.next()}>Buy Now</Button>
                      </Col>
                    </Row>
                  </Fragment>
                ) : (
                  <h3>No order found</h3>
                )
                }
              </div>
              <div id="competitions-form-3" role="tabpanel" className="bs-stepper-pane fade" aria-labelledby="stepperFormTrigger3">
                <PaymentForm
                  stepper={stepper}
                  customer={cartCustomer}
                  disableEmail={false}
                  getCustomer={getCustomer}
                  setShowCancelModal={setShowCancelModal}
                />
              </div>
            </div>
          </div>
          <Modal show={showCancelModal} onHide={() => setShowCancelModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Cancel Order ?</Modal.Title>
            </Modal.Header>
            <Modal.Body>Changes you made may not be saved.</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowCancelModal(false)}>
                Cancel
              </Button>
              <Button variant="primary" onClick={() => window.location.href = `/competition/${eventid}`}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showParticipantModal} onHide={() => setShowParticipantModal(false)} centered>
            <form onSubmit={handleSubmitParticipants(onSubmitParticipants)}>
              <Modal.Header closeButton>
                <Modal.Title>Competition Registration Form</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row className="space-mb-15">
                  <Col lg={3} md={12} className="space-pt-7 text-label">
                    <span className="form-label">Name:</span>
                  </Col>
                  <Col lg={9} md={12} className="text-left">
                    <Controller
                      name="participantsname"
                      control={controlParticipants}
                      defaultValue={!isEmpty(selectedParticipant) && selectedParticipant.edit ? selectedParticipant.edit.name : ""}
                      render={({ onChange, value }) => (
                        <FormControl
                          type="text"
                          defaultValue={value}
                          placeholder="Full name as per IC / Passport"
                          onChange={(e) => onChange(e.target.value)}
                        />
                      )}
                      rules={{ required: "This field is required" }}
                    />
                    {<span className="error-text mobile-size">{errorsParticipants && errorsParticipants.participantsname ? errorsParticipants.participantsname.message : ""}</span>}
                  </Col>
                </Row>
                <Row className="space-mb-15">
                  <Col lg={3} md={12} className="space-pt-7 text-label">
                    <span className="form-label">Email:</span>
                  </Col>
                  <Col lg={9} md={12} className="text-left">
                    <Controller
                      name="participantsemail"
                      control={controlParticipants}
                      defaultValue={!isEmpty(selectedParticipant) && selectedParticipant.edit ? selectedParticipant.edit.email : ""}
                      render={({ onChange, value }) => (
                        <FormControl
                          type="email"
                          defaultValue={value}
                          placeholder="example@email.com"
                          onChange={(e) => onChange(e.target.value)}
                        />
                      )}
                      rules={{ required: "This field is required" }}
                    />
                    {<span className="error-text mobile-size">{errorsParticipants && errorsParticipants.participantsemail ? errorsParticipants.participantsemail.message : ""}</span>}
                  </Col>
                </Row>
                <Row className="space-mb-15">
                  <Col lg={3} md={12} className="space-pt-7 text-label">
                    <span className="form-label">Mobile No:</span>
                  </Col>
                  <Col lg={9} md={12} className="text-left">
                    <Controller
                      name="participantsmobile"
                      control={controlParticipants}
                      defaultValue={!isEmpty(selectedParticipant) && selectedParticipant.edit ? selectedParticipant.edit.mobile : ""}
                      render={({ onChange, value }) => (
                        <PhoneInput
                          country='my'
                          value={value}
                          preferredCountries={['my']}
                          enableSearch={true}
                          onChange={(value, data) => {
                            onChange(value);
                            handleChangeParticipantMobile(value, data);
                          }}
                        />
                      )}
                      rules={{ required: "This field is required" }}
                    />
                    {<span className="error-text mobile-size">{errorsParticipants && errorsParticipants.participantsmobile ? errorsParticipants.participantsmobile.message : ""}</span>}
                  </Col>
                </Row>
                <Row className="space-mb-15">
                  <Col lg={3} md={12} className="space-pt-7 text-label">
                    <span className="form-label">Birth Date:</span>
                  </Col>
                  <Col lg={9} md={12} className="text-left dob-box">
                    <Controller
                      name="participantsdob"
                      control={controlParticipants}
                      defaultValue={!isEmpty(selectedParticipant) && selectedParticipant.edit ? dayjs(selectedParticipant.edit.dob).startOf('day').toDate() : null}
                      render={({ onChange, value }) => (
                        <DatePicker
                          className="form-control dob"
                          dateFormat="dd MMMM yyyy"
                          selected={value}
                          onChange={(value) => {
                            onChange(value);
                          }}
                          maxDate={dayjs().startOf('day').toDate()}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                        />
                      )}
                      rules={{ required: "This field is required" }}
                    />
                    {<span className="error-text mobile-size">{errorsParticipants && errorsParticipants.participantsdob ? errorsParticipants.participantsdob.message : participantAgeError}</span>}
                  </Col>
                </Row>
                <Row className="space-mb-15">
                  <Col lg={3} md={12} className="space-pt-7 text-label">
                    <span className="form-label">Gender:</span>
                  </Col>
                  <Col lg={9} md={12} className="text-left">
                    <Controller
                      name="participantsgender"
                      control={controlParticipants}
                      defaultValue={!isEmpty(selectedParticipant) && selectedParticipant.edit ? selectedParticipant.edit.gender : ""}
                      render={({ onChange, value }) => (
                        <FormControl
                          as="select"
                          defaultValue={value}
                          onChange={(e) => onChange(e.target.value)}
                        >
                          <option value='' key=''></option>
                          <option value='Male' key='Male'>Male</option>
                          <option value='Female' key='Female'>Female</option>
                        </FormControl>
                      )}
                      rules={{ required: "This field is required" }}
                    />
                    {<span className="error-text mobile-size">{errorsParticipants && errorsParticipants.participantsgender ? errorsParticipants.participantsgender.message : participantGenderError}</span>}
                  </Col>
                </Row>
                <Row className="space-mb-15">
                  <Col lg={3} md={12} className="space-pt-7 text-label">
                    <span className="form-label">Nationality:</span>
                  </Col>
                  <Col lg={9} md={12} className="text-left">
                    <Controller
                      name="nationality"
                      control={controlParticipants}
                      defaultValue={!isEmpty(selectedParticipant) && selectedParticipant.edit ? selectedParticipant.edit.nationality : "Malaysia"}
                      render={({ onChange, value }) => (
                        <FormControl
                          as="select"
                          defaultValue={value}
                          onChange={(e) => onChange(e.target.value)}
                        >
                          {
                            countries.map(country => (
                              <option value={country.name} key={country.code}>{country.name}</option>
                            ))
                          }
                        </FormControl>
                      )}
                      rules={{ required: "This field is required" }}
                    />
                    {<span className="error-text mobile-size">{errorsParticipants && errorsParticipants.nationality ? errorsParticipants.nationality.message : ""}</span>}
                  </Col>
                </Row>
                <Row className="space-mb-15">
                  <Col lg={3} md={12} className="space-pt-7 text-label">
                    <span className="form-label">Emergency Contact:</span>
                  </Col>
                  <Col lg={9} md={12} className="text-left">
                    <Controller
                      name="emergencycontact"
                      control={controlParticipants}
                      defaultValue={!isEmpty(selectedParticipant) && selectedParticipant.edit ? selectedParticipant.edit.emergencycontact : ""}
                      render={({ onChange, value }) => (
                        <FormControl
                          type="text"
                          defaultValue={value}
                          placeholder="Full name as per IC / Passport"
                          onChange={(e) => onChange(e.target.value)}
                        />
                      )}
                      rules={{ required: "This field is required" }}
                    />
                    {<span className="error-text mobile-size">{errorsParticipants && errorsParticipants.emergencycontact ? errorsParticipants.emergencycontact.message : ""}</span>}
                  </Col>
                </Row>
                <Row className="space-mb-15">
                  <Col lg={3} md={12} className="space-pt-7 text-label">
                    <span className="form-label">Emergency Phone:</span>
                  </Col>
                  <Col lg={9} md={12} className="text-left">
                    <Controller
                      name="emergencyphone"
                      control={controlParticipants}
                      defaultValue={!isEmpty(selectedParticipant) && selectedParticipant.edit ? selectedParticipant.edit.emergencyphone : ""}
                      render={({ onChange, value }) => (
                        <PhoneInput
                          country='my'
                          value={value}
                          preferredCountries={['my']}
                          enableSearch={true}
                          onChange={(value, data) => {
                            onChange(value);
                            handleChangeEmergencyPhone(value, data);
                          }}
                        />
                      )}
                      rules={{ required: "This field is required" }}
                    />
                    {<span className="error-text mobile-size">{errorsParticipants && errorsParticipants.emergencyphone ? errorsParticipants.emergencyphone.message : ""}</span>}
                  </Col>
                </Row>
                <Row className="space-mb-15">
                  <Col lg={3} md={12} className="space-pt-7 text-label">
                    <span className="form-label">Category:</span>
                  </Col>
                  <Col lg={9} md={12} className="text-left">
                    <Controller
                      name="category"
                      control={controlParticipants}
                      defaultValue={!isEmpty(selectedParticipant) && selectedParticipant.edit ? selectedParticipant.edit.category : ""}
                      render={({ onChange, value }) => (
                        <FormControl
                          as="select"
                          defaultValue={value}
                          onChange={(e) => onChange(e.target.value)}
                        >
                          <option value='' key=''></option>
                          <option value='Advanced' key='Advanced'>Advanced</option>
                          <option value='Novice' key='Novice'>Novice</option>
                        </FormControl>
                      )}
                      rules={{ required: "This field is required" }}
                    />
                    {<span className="error-text mobile-size">{errorsParticipants && errorsParticipants.category ? errorsParticipants.category.message : participantCategoryError}</span>}
                  </Col>
                </Row>
                <Row className="space-mb-15">
                  <Col lg={3} md={12} className="space-pt-7 text-label">
                    <span className="form-label">T-shirt Size:</span>
                  </Col>
                  <Col lg={9} md={12} className="text-left">
                    <Controller
                      name="tshirt_size"
                      control={controlParticipants}
                      defaultValue={!isEmpty(selectedParticipant) && selectedParticipant.edit ? selectedParticipant.edit.tshirt_size : ""}
                      render={({ onChange, value }) => (
                        <FormControl
                          as="select"
                          defaultValue={value}
                          onChange={(e) => onChange(e.target.value)}
                        >
                          <option value='' key=''></option>
                          <option value='XXS' key='XXS'>XXS</option>
                          <option value='XS' key='XS'>XS</option>
                          <option value='S' key='S'>S</option>
                          <option value='M' key='M'>M</option>
                          <option value='L' key='L'>L</option>
                          <option value='XL' key='XL'>XL</option>
                          <option value='XXL' key='XXL'>XXL</option>
                        </FormControl>
                      )}
                      rules={{ required: "This field is required" }}
                    />
                    <div><center><small><a href="https://www.projectrock.com.my/wp-content/uploads/2020/05/1024-x-1024-Tshirt-SIzing.jpg" target="_blank">T-shirt Size Chart</a></small></center></div>
                    {<span className="error-text mobile-size">{errorsParticipants && errorsParticipants.tshirt_size ? errorsParticipants.tshirt_size.message : ""}</span>}
                  </Col>
                </Row>
                <Row className="space-mb-15">
                  <Col lg={3} md={12} className="space-pt-7 text-label">
                    {/* <span className="form-label">Agreement & Waiver:</span> */}
                  </Col>
                  <Col lg={9} md={12} className="text-left">
                    <Form.Check type="checkbox" id="waiver">
                      <Form.Check.Input type="checkbox" isValid required />
                      <Form.Label>I agree to the <a href="http://projectrock.com.my/vertex-waiver" target="_blank">waiver agreement</a></Form.Label>
                      <Form.Control.Feedback type="invalid">You must agree before submitting.</Form.Control.Feedback>
                    </Form.Check>
                    {<span className="error-text mobile-size">{errorsParticipants && errorsParticipants.waiver ? errorsParticipants.waiver.message : ""}</span>}
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="outline-primary" type="submit">
                  Continue
                </Button>
                <Button variant="outline-primary" onClick={handleCancelUpdate}>
                  Cancel
                </Button>
              </Modal.Footer>
            </form>
          </Modal>
          <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Delete Registration</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you confirm to delete registration?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => handleDeleteParticipant(false)}>
                Cancel
              </Button>
              <Button variant="primary" onClick={() => handleDeleteParticipant(true)}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>

          {payment && !payment.error &&
            <Ipay88Form
              payment={payment}
              showLoading={showLoading}
              setShowLoading={setShowLoading}
            />
          }
        </Col>
      </Row>
    </Container>
  );
};

export default Competitions;
