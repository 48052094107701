import React, { Fragment } from 'react';
import { Navbar, Nav } from 'react-bootstrap'
import { useLocation } from 'react-router-dom';
import MobileNav from './MobileNav';

const Header = () => {
  const { pathname } = useLocation();

  return (
    <Fragment>
      <Navbar expand="lg" sticky="top" id="Navbar">
        <Navbar.Brand href="https://www.projectrock.com.my">
          <img
            src={process.env.PUBLIC_URL + "/assets/images/logo/logo.png"}
            className="img-fluid"
            alt=""
          />
        </Navbar.Brand>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">
            {/* <Nav.Link className={`${pathname.includes('competition') ? 'active' : ''}`} href="/competition/vertex-2024">Vertex 2024</Nav.Link> */}
            <Nav.Link className={`${pathname.includes('workshop') ? 'active' : ''}`} href="/workshop">Beginner's Bundle</Nav.Link>
            <Nav.Link href="https://www.projectrock.com.my">Project Rock Homepage</Nav.Link>
            <Nav.Link className={`${pathname.includes('online_ticket') || pathname === '/' ? 'active' : ''}`} href="/">Online Tickets</Nav.Link>
            <Nav.Link className={`${pathname.includes('change_date') ? 'active' : ''}`} href="/change_date">Change Visit Date</Nav.Link>
            {/*<Nav.Link href="/renew_member">Membership Renewal</Nav.Link>*/}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <MobileNav pageWrapId={"Navbar"} outerContainerId={"App"} className="mobile-tablet" />
    </Fragment>
  )
}

export default Header;